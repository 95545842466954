
import { defineComponent, onMounted, ref, reactive, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { hideModal } from "@/core/helpers/dom";
import moment from "moment";

interface editSetting {
  title: string;
  item: string;
  defaultPrice: any;
  tax: string;
  expiredInDays: string;
  customPrice: any;
  schoolId: string;
  idDiskon: string;
  namaDiskon: string;
  banyakDiskon: string;
  expDateDiskon: string;
  statusCustomPrice: string;
  statusSaveCustomPrice: string;
}

interface items {
  listSchool: any;
  listProduct: any;
  customPrice: any;
  listDiskon: any;
  roleLogin: string;
  endPointUpdate: string;
  endPointAddCustom: string;
  endPointUpdateCustom: string;
}

interface errorsMessage {
  errorschoolId: string;
  errortitle: string;
  erroritem: string;
  errornamadiskon: string;
  errortanggaldiskon: string;
  errorbanyakdiskon: string;
  errorcustomprice: string;
}
interface customPrice {
  schoolId: string;
  price: number;
  discounts: discount[];
}
interface discount {
  id: string;
  title: string;
  dueDate: any;
  discount: number;
}

export default defineComponent({
  name: "Setting Finance",
  components: {
    ErrorMessage,
    Field,
    Loading,
    Form,
  },

  setup() {
    const uploadModalRef = ref<null | HTMLElement>(null);
    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    let schoolId = reactive<String>("");

    let customPrice = reactive<customPrice>({
      schoolId: "",
      price: 0,
      discounts: [],
    });

    let discount = reactive<discount>({
      id: "",
      title: "",
      dueDate: moment().format("YYYY-MM-DD"),
      discount: 0,
    });

    let items = reactive<items>({
      listSchool: [],
      listProduct: [],
      customPrice: [],
      listDiskon: [],
      roleLogin: "",
      endPointUpdate: "",
      endPointAddCustom: "",
      endPointUpdateCustom: "",
    });

    const editSettingValidator = Yup.object().shape({});

    const editSetting = reactive<editSetting>({
      title: "",
      schoolId: "",
      item: "",
      defaultPrice: "",
      tax: "",
      expiredInDays: "",
      customPrice: [],
      idDiskon: "",
      namaDiskon: "",
      banyakDiskon: "",
      expDateDiskon: "",
      statusCustomPrice: "",
      statusSaveCustomPrice: "",
    });

    const errorsMessage = reactive<errorsMessage>({
      errorschoolId: "",
      errortitle: "",
      erroritem: "",
      errornamadiskon: "",
      errortanggaldiskon: "",
      errorbanyakdiskon: "",
      errorcustomprice: "",
    });

    onBeforeMount(() => {
      schoolId =
        localStorage.getItem("schoolId") ||
        JSON.parse(localStorage.getItem("user_account")!).schoolId;

      editSetting.schoolId = localStorage.getItem("schoolId")!;
      editSetting.item = "Product";
    });

    const setRoleLogin = () => {
      items.roleLogin = JSON.parse(localStorage.getItem("user_account")!).role;
      switch (items.roleLogin) {
        case "SUPER_ADMIN":
          items.endPointUpdate =
            "crmv2/main_invoice/invoice/office/invoice/settings/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom";
          break;
        case "SALES_LEADER":
          items.endPointUpdate = "crmv2/main_invoice/invoice/invoice/setting/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/invoice/setting/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/invoice/setting/custom";
          break;
        case "SALES_AREA":
          items.endPointUpdate = "crmv2/main_invoice/invoice/invoice/setting/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/invoice/setting/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/invoice/setting/custom";
          break;
        case "CS_LEADER":
          items.endPointUpdate = "crmv2/main_invoice/invoice/invoice/setting/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/invoice/setting/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/invoice/setting/custom";
          break;
        case "CS_MEMBER":
          items.endPointUpdate = "crmv2/main_invoice/invoice/invoice/setting/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/invoice/setting/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/invoice/setting/custom";
          break;
        case "ADMIN_FINANCE":
          items.endPointUpdate =
            "crmv2/main_invoice/invoice/office/invoice/settings/";
          items.endPointUpdateCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom/";
          items.endPointAddCustom =
            "crmv2/main_invoice/invoice/office/invoice/settings/custom";
          break;
      }
    };

    const getListSchool = () => {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        (response) => {
          items.listSchool = response.data;
        }
      );
    };

    const getlistproduk = () => {
      const itemsproduk = [
        { name: "Registration", id: "Registration" },
        { name: "Migration", id: "Migration" },
        { name: "Order", id: "Order" },
        { name: "Custom App", id: "Custom App" },
        { name: "Monthly Fee", id: "Monthly Fee" },
        { name: "Package", id: "Package" },
        { name: "Other", id: "Other" },
        { name: "Product", id: "Product" },
      ];

      items.listProduct = itemsproduk;
    };

    const changeProduct = () => {
      if (editSetting.item == "Registration") editSetting.defaultPrice = 20000;
      else editSetting.defaultPrice = 0;
    };

    const getIdInvoice = () => {
      isLoading.value = true;
      ApiService.getWithoutSlug(`${items.endPointUpdate}${route.params._id}`)
        .then((res) => {
          editSetting.schoolId = res.data.schoolId;
          editSetting.title = res.data.title;
          editSetting.item = res.data.item;
          editSetting.tax = res.data.tax;
          editSetting.defaultPrice = res.data.defaultPrice;
          editSetting.expiredInDays = res.data.expiredInDays;
          customPrice.price = res.data.defaultPrice;

          editSetting.statusCustomPrice = "not_ready";
          if (res.data.custom.length > 0) {
            let index = res.data.custom.findIndex(
              (item: any) => item.schoolId == schoolId
            );
            if (index != -1) {
              editSetting.statusCustomPrice = "ready";
              customPrice.schoolId = res.data.custom[index].schoolId;
              customPrice.price = res.data.custom[index].price;
              if (res.data.custom[index].discounts.length > 0) {
                discount.title = res.data.custom[index].discounts[0].title;
                discount.id = res.data.custom[index].discounts[0].id;
                discount.dueDate = res.data.custom[index].discounts[0].dueDate;
                discount.discount =
                  res.data.custom[index].discounts[0].discount;
              }
            }
          }
          isLoading.value = false;
        })
        .catch(({ response }) => {
          isLoading.value = false;
        });
    };

    const saveChanges1 = () => {
      if (
        editSetting.schoolId === "" ||
        editSetting.title === "" ||
        editSetting.item === ""
      ) {
        if (editSetting.schoolId === "") {
          errorsMessage.errorschoolId = "Sekolah Wajib Diisi";
        }
        if (editSetting.title === "") {
          errorsMessage.errortitle = "Nama Produk Wajib Diisi";
        }
        if (editSetting.item === "") {
          errorsMessage.erroritem = "Tipe Produk Wajib Diisi";
        }
        toast.error("Form belum lengkap");
      } else {
        isLoading.value = true;
        errorsMessage.errorschoolId = "";
        errorsMessage.errortitle = "";
        errorsMessage.erroritem = "";

        const dataPost = {
          title: editSetting.title,
          defaultPrice: editSetting.defaultPrice,
          expiredInDays: editSetting.expiredInDays,
          tax: editSetting.tax,
        };

        submitButton.value?.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          ApiService.putWithData(
            items.endPointUpdate + route.params._id,
            dataPost
          )
            .then((res) => {
              toast.success("Berhasil Edit Setting Invoices");
              router.push({ path: "/superadmin/setting-invoices-product" });
              isLoading.value = false;
            })
            .catch((e) => {
              toast.error(e.response.data.detail);
              isLoading.value = false;
            });

          submitButton.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const openaddmodal = () => {
      editSetting.statusSaveCustomPrice = "add";
      discount.id = "";
      discount.title = "";
      discount.dueDate = "";
      discount.discount = 0;
      errorsMessage.errornamadiskon = "";
      errorsMessage.errorbanyakdiskon = "";
      errorsMessage.errortanggaldiskon = "";
      errorsMessage.errorcustomprice = "";
    };
    const openeditmodal = () => {
      editSetting.statusSaveCustomPrice = "edit";
    };
    const validate = () => {
      let valid = {
        title: true,
        discount: true,
        dueDate: true,
        customPrice: true,
      };

      if (discount.title === "") {
        valid.title = false;
        errorsMessage.errornamadiskon = "Nama Diskon Wajib Diisi";
      }

      if (discount.dueDate === "") {
        valid.dueDate = false;
        errorsMessage.errortanggaldiskon = "Tangal Diskon Wajib Diisi";
      }

      if (
        discount.discount < 1 ||
        discount.discount == undefined ||
        discount.discount == null
      ) {
        valid.dueDate = false;
        errorsMessage.errorbanyakdiskon = "Diskon Wajib Diisi";
      }

      if (
        customPrice.price < 1 ||
        customPrice.price == undefined ||
        customPrice.price == null
      ) {
        valid.dueDate = false;
        errorsMessage.errorcustomprice = "Harga Wajib Diisi";
      }

      return Object.values(valid).reduce((prev, next) => prev && next);
    };

    const saveModal = () => {
      if (!validate()) {
        return;
      }

      let url = ``;
      let method = ``;
      let message = ``;
      let listDiskon = {
        schoolId: schoolId,
        price: customPrice.price,
        discounts: [
          {
            id:
              editSetting.statusSaveCustomPrice == "edit" ? discount.id : null,
            title: discount.title,
            dueDate: discount.dueDate,
            discount: discount.discount,
          },
        ],
      };

      if (editSetting.statusSaveCustomPrice == "edit") {
        url = `${items.endPointUpdateCustom}${route.params._id}`;
        method = "putWithData";
        message = "Berhasil Edit Custom Diskon Invoices";
      } else {
        url = `${items.endPointAddCustom}?invSettingId=${route.params._id}`;
        method = "postWithData";
        message = "Berhasil Tambah Custom Diskon Invoices";
      }

      isLoading.value = true;

      setTimeout(() => {
        ApiService[method](url, listDiskon)
          .then((res) => {
            toast.success(message);
            hideModal(uploadModalRef.value);
            getIdInvoice();
            isLoading.value = false;
          })
          .catch((e) => {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);

      // if (discount.id && editSetting.statusSaveCustomPrice == "edit") {
      //   isLoading.value = true;

      //   setTimeout(() => {
      //     ApiService[method](url, listDiskon)
      //       .then((res) => {
      //         toast.success("Berhasil Edit Custom Diskon Invoices");
      //         hideModal(uploadModalRef.value);
      //         getIdInvoice();
      //         isLoading.value = false;
      //       })
      //       .catch((e) => {
      //         toast.error(e.response.data.detail);
      //         isLoading.value = false;
      //       });

      //     submitButton.value?.removeAttribute("data-kt-indicator");
      //   }, 2000);
      // } else {
      //   isLoading.value = true;

      //   setTimeout(() => {
      //     ApiService[method](url, listDiskon)
      //       .then((res) => {
      //         toast.success("Berhasil Add Custom Diskon Invoices");
      //         hideModal(uploadModalRef.value);
      //         getIdInvoice();
      //         isLoading.value = false;
      //       })
      //       .catch((e) => {
      //         toast.error(e.response.data.detail);
      //         isLoading.value = false;
      //       });

      //     submitButton.value?.removeAttribute("data-kt-indicator");
      //   }, 2000);
      // }
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_FINANCE);
      setCurrentPageBreadcrumbs("Ubah Setting Invoice", ["Data"]);
      setRoleLogin();
      getListSchool();
      getIdInvoice();
      getlistproduk();
    });

    return {
      submitButton,
      saveChanges1,
      openaddmodal,
      openeditmodal,
      saveModal,
      uploadModalRef,
      editSetting,
      editSettingValidator,
      items,
      discount,
      customPrice,
      errorsMessage,
      setRoleLogin,
      getIdInvoice,
      getListSchool,
      getlistproduk,
      changeProduct,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
